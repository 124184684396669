import { EnergyTag } from './all.enums';
import { ENERGY_DEVICE_MAP, EnergyType, heatTypeToDevice, waterTypeToDevice } from './enums/property.enums';

export function parseJwt(token: string): any {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join(''),
  );

  return JSON.parse(jsonPayload);
}

export function getEnergyDevices(energyType: EnergyType, isWater = false): { value: number; label: string }[] {
  const devices = isWater ? waterTypeToDevice.get(energyType) : heatTypeToDevice.get(energyType);

  return (
    devices?.map((device) => ({
      value: device,
      label: ENERGY_DEVICE_MAP.get(device) || '',
    })) || []
  );
}

export function parseDpeTag(tag: string): EnergyTag | null {
  switch (tag) {
    case 'A':
      return EnergyTag.A;
    case 'B':
      return EnergyTag.B;
    case 'C':
      return EnergyTag.C;
    case 'D':
      return EnergyTag.D;
    case 'E':
      return EnergyTag.E;
    case 'F':
      return EnergyTag.F;
    case 'G':
      return EnergyTag.G;
    default:
      return null;
  }
}

export function capitalize(s: string): string {
  return s ? s[0].toUpperCase() + s.slice(1) : '';
}

export function formatFirstName(firstName?: string): string {
  return firstName ? firstName.split(' ').map(capitalize).join(' ') : '';
}

export function formatLastName(lastName?: string): string {
  return lastName?.toUpperCase() || '';
}

export function formatISOUTCDate(date: Date): string {
  const offset = date.getTimezoneOffset();
  const newDate = new Date(date.getTime() - offset * 60 * 1000);

  return newDate.toISOString().split('T')[0];
}

export function roundThreeDigits(value: number): number {
  const decimals = value >= 100 ? 0 : value >= 10 ? 1 : 2;

  return Math.round(value * 10 ** decimals) / 10 ** decimals;
}

export function haveFiltersChanged(
  previousFilters: { [id: number | string]: any[] },
  newFilters: { [id: number]: any[] },
): boolean {
  return Object.entries(newFilters).some(([id, currentValue]) => {
    const previousValue = previousFilters[id];
    const removedValues = (previousValue || []).filter((item: any) => !currentValue?.includes(item));
    const addedValues = (currentValue || []).filter((item: any) => !previousValue?.includes(item));

    return !!removedValues.length || !!addedValues.length;
  });
}
